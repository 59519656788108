var render = function () {
  var _vm$users, _vm$users2, _vm$users3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-wrapper"
  }, [_vm.invitingUser.length ? _c('div', [_c('b-button', {
    staticClass: "mb-2 backButton",
    attrs: {
      "type": "submit",
      "variant": "light"
    },
    on: {
      "click": function click($event) {
        return _vm.listReferrals(null);
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "color": "black",
      "icon": "chevron-left"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t('users.referralsHeader', {
    name: "".concat(_vm.invitingUser[_vm.invitingUser.length - 1].firstName, " ").concat(_vm.invitingUser[_vm.invitingUser.length - 1].lastName)
  })) + " ")], 1) : _vm._e(), _c('b-table', {
    staticClass: "mb-0",
    attrs: {
      "id": "table",
      "items": (_vm$users = _vm.users) === null || _vm$users === void 0 ? void 0 : _vm$users.items,
      "per-page": _vm.perPage,
      "fields": _vm.getTableFields,
      "busy": _vm.loading,
      "striped": "",
      "responsive": "",
      "small": "",
      "sticky-header": "85vh",
      "no-local-sorting": ""
    },
    on: {
      "sort-changed": _vm.sortTable
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-center text-danger my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('loading')))])], 1)];
      },
      proxy: true
    }, {
      key: "cell(createdAt)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.formatCreatedAt(data.value)) + " ")];
      }
    }, {
      key: "cell(referralsCount)",
      fn: function fn(row) {
        return [_c('div', {
          staticClass: "referrals",
          on: {
            "click": function click($event) {
              return _vm.listReferrals(row.item);
            }
          }
        }, [_vm._v(" " + _vm._s(row.item.referralsCount) + " ")])];
      }
    }, {
      key: "cell(storageUsed)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.value.toFixed(2) + ' GB') + " ")];
      }
    }, {
      key: "cell(mostImportantOptions)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.getSelectedMostImportantOptions(data.value).map(function (index) {
          return _vm.$t('most-important.option') + " ".concat((index + 1).toString());
        }).join(', ')) + " ")];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(row) {
        return [row.item.active && !_vm.getDisabledState(row.item.email) ? _c('b-button', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal",
            value: "deactivate-modal".concat(row.item.id),
            expression: "`deactivate-modal${row.item.id}`"
          }, {
            name: "b-popover",
            rawName: "v-b-popover.hover.top",
            value: _vm.$t('users.table.deactivate'),
            expression: "$t('users.table.deactivate')",
            modifiers: {
              "hover": true,
              "top": true
            }
          }],
          attrs: {
            "id": "btn".concat(row.item.id),
            "type": "submit",
            "variant": "outline-primary"
          }
        }, [_c('img', {
          staticClass: "icon",
          attrs: {
            "src": require("@/assets/icon/deactivate.svg")
          }
        })]) : !row.item.active ? _c('b-button', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal",
            value: "reactivate-modal".concat(row.item.id),
            expression: "`reactivate-modal${row.item.id}`"
          }, {
            name: "b-popover",
            rawName: "v-b-popover.hover.top",
            value: _vm.$t('users.table.reactivate'),
            expression: "$t('users.table.reactivate')",
            modifiers: {
              "hover": true,
              "top": true
            }
          }],
          attrs: {
            "id": "btn".concat(row.item.id),
            "type": "submit",
            "variant": "outline-primary"
          }
        }, [_c('img', {
          staticClass: "icon",
          attrs: {
            "src": require("@/assets/icon/reactivate.svg")
          }
        })]) : _vm._e(), _c('b-button', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal",
            value: "password-reset-modal".concat(row.item.id),
            expression: "`password-reset-modal${row.item.id}`"
          }, {
            name: "b-popover",
            rawName: "v-b-popover.hover.top",
            value: _vm.$t('users.table.password-reset'),
            expression: "$t('users.table.password-reset')",
            modifiers: {
              "hover": true,
              "top": true
            }
          }],
          attrs: {
            "id": "btn".concat(row.item.id),
            "type": "submit",
            "variant": "outline-primary"
          }
        }, [_c('img', {
          staticClass: "icon",
          attrs: {
            "src": require("@/assets/icon/password-reset.svg")
          }
        })]), _c('b-button', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal",
            value: "delete-user-modal".concat(row.item.id),
            expression: "`delete-user-modal${row.item.id}`"
          }, {
            name: "b-popover",
            rawName: "v-b-popover.hover.top",
            value: _vm.$t('users.table.delete-user'),
            expression: "$t('users.table.delete-user')",
            modifiers: {
              "hover": true,
              "top": true
            }
          }],
          attrs: {
            "id": "btn".concat(row.item.id),
            "type": "submit",
            "variant": "outline-primary"
          }
        }, [_c('img', {
          staticClass: "icon",
          attrs: {
            "src": require("@/assets/icon/user-delete.svg")
          }
        })]), _c('confirmation-modal', {
          attrs: {
            "id": "deactivate-modal".concat(row.item.id),
            "variant": "danger",
            "yes-key": "users.modal.deactivate.ok",
            "loading": _vm.updateLoading
          },
          on: {
            "on-click-yes": function onClickYes($event) {
              return _vm.softDeleteOnClick(row.item.id);
            }
          },
          scopedSlots: _vm._u([{
            key: "body-text",
            fn: function fn() {
              return [_c('h2', {
                staticClass: "modal-title"
              }, [_vm._v(" " + _vm._s(_vm.$t('users.modal.deactivate.title')) + " ")]), _c('p', {
                staticClass: "modal-p"
              }, [_vm._v(" " + _vm._s(_vm.$t('users.modal.deactivate.body')) + " "), _c('strong', [_vm._v(" " + _vm._s("".concat(row.item.firstName, " ").concat(row.item.lastName)) + " ")])])];
            },
            proxy: true
          }], null, true)
        }), _c('confirmation-modal', {
          attrs: {
            "id": "reactivate-modal".concat(row.item.id),
            "yes-key": "users.modal.reactivate.ok",
            "loading": _vm.updateLoading
          },
          on: {
            "on-click-yes": function onClickYes($event) {
              return _vm.reactivateOnClick(row.item.id);
            }
          },
          scopedSlots: _vm._u([{
            key: "body-text",
            fn: function fn() {
              return [_c('h2', {
                staticClass: "modal-title"
              }, [_vm._v(" " + _vm._s(_vm.$t('users.modal.reactivate.title')) + " ")]), _c('p', {
                staticClass: "modal-p"
              }, [_vm._v(" " + _vm._s(_vm.$t('users.modal.reactivate.body')) + " "), _c('strong', [_vm._v(" " + _vm._s("".concat(row.item.firstName, " ").concat(row.item.lastName)) + " ")])])];
            },
            proxy: true
          }], null, true)
        }), _c('confirmation-modal', {
          attrs: {
            "id": "password-reset-modal".concat(row.item.id),
            "yes-key": "users.modal.password-reset.yes",
            "no-key": "users.modal.password-reset.no",
            "loading": _vm.updateLoading
          },
          on: {
            "on-click-yes": function onClickYes($event) {
              return _vm.passwordResetOnClick(row.item);
            }
          },
          scopedSlots: _vm._u([{
            key: "body-text",
            fn: function fn() {
              return [_c('h2', {
                staticClass: "modal-title"
              }, [_vm._v(" " + _vm._s(_vm.$t('users.modal.password-reset.title')) + " ")]), _c('i18n', {
                staticClass: "modal-p",
                attrs: {
                  "path": "users.modal.password-reset.body",
                  "tag": "p"
                },
                scopedSlots: _vm._u([{
                  key: "name",
                  fn: function fn() {
                    return [_c('strong', [_vm._v(_vm._s("".concat(row.item.firstName, " ").concat(row.item.lastName)))])];
                  },
                  proxy: true
                }], null, true)
              })];
            },
            proxy: true
          }], null, true)
        }), _c('confirmation-modal', {
          attrs: {
            "id": "delete-user-modal".concat(row.item.id),
            "yes-key": "users.modal.delete-user.yes",
            "no-key": "users.modal.delete-user.no",
            "loading": _vm.updateLoading
          },
          on: {
            "on-click-yes": function onClickYes($event) {
              return _vm.deleteUserOnClick(row.item);
            }
          },
          scopedSlots: _vm._u([{
            key: "body-text",
            fn: function fn() {
              return [_c('h2', {
                staticClass: "modal-title"
              }, [_vm._v(" " + _vm._s(_vm.$t('users.modal.delete-user.title')) + " ")]), _c('i18n', {
                staticClass: "modal-p",
                attrs: {
                  "path": "users.modal.delete-user.body",
                  "tag": "p"
                },
                scopedSlots: _vm._u([{
                  key: "name",
                  fn: function fn() {
                    return [_c('strong', [_vm._v(_vm._s("".concat(row.item.firstName, " ").concat(row.item.lastName)))])];
                  },
                  proxy: true
                }], null, true)
              })];
            },
            proxy: true
          }], null, true)
        })];
      }
    }])
  }), _c('b-pagination', {
    staticClass: "ml-3 mt-3",
    attrs: {
      "value": (_vm$users2 = _vm.users) === null || _vm$users2 === void 0 ? void 0 : _vm$users2.currentPage,
      "total-rows": (_vm$users3 = _vm.users) === null || _vm$users3 === void 0 ? void 0 : _vm$users3.totalItems,
      "per-page": _vm.perPage,
      "aria-controls": "table",
      "align": "right",
      "limit": "5",
      "hide-goto-end-buttons": ""
    },
    on: {
      "change": function change($event) {
        return _vm.onChangePage($event);
      }
    }
  }, [_c('template', {
    slot: "prev-text"
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": require("@/assets/table/prev.svg")
    }
  }), _vm._v(" " + _vm._s(_vm.$t('prev')) + " ")]), _c('template', {
    slot: "next-text"
  }, [_vm._v(" " + _vm._s(_vm.$t('next')) + " "), _c('img', {
    staticClass: "icon",
    attrs: {
      "src": require("@/assets/table/next.svg")
    }
  })])], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }