






import UsersTable from '@/active-components/users/UsersTable.vue';
import { Component, Vue } from 'vue-property-decorator';
import store from '@/store';

@Component({
    beforeRouteLeave(from, to, next) {
        store.dispatch('userStorage/search', null).then(() => next());
    },
    components: {
        UsersTable,
    },
})
export default class Users extends Vue {}
