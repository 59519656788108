

































































































































































































































import { Component } from 'vue-property-decorator';
import { mapActions, mapGetters } from 'vuex';
import { mixins } from 'vue-class-component';
import { BvTableCtxObject } from 'bootstrap-vue';
import Pageable from '@/model/Pageable';
import { User, UserFetchRequest } from '@/model/User';
import { Field } from '@/model/table/Field';
import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';
import ToastVariant from '@/model/enums/toast/toast-variant';
import ToastMixin from '@/mixins/ToastMixin';
import { ApiError } from '@/model/ApiError';
import { MostImportantItem } from '@/model/MostImportantItem';
import formatISODateStringToCreatedAtString from '@/lib/helper/date-helper';

@Component({
    components: {
        ConfirmationModal,
    },
    methods: {
        ...mapActions({
            fetchUsers: 'userStorage/fetchUsers',
            deactivateUserById: 'userStorage/deactivateUserById',
            reactivateUserById: 'userStorage/reactivateUserById',
            deleteUserById: 'userStorage/deleteUserById',
            resetPassword: 'userStorage/resetPassword',
            fetchReferrals: 'userStorage/fetchReferrals',
            fetchMostImportantOptions: 'mostImportantStorage/fetch',
        }),
    },
    computed: {
        ...mapGetters({
            users: 'userStorage/getUsers',
            user: 'authenticationStorage/getUser',
            error: 'userStorage/getError',
            mostImportantItems: 'mostImportantStorage/getItems',
        }),
    },
})
export default class UsersTable extends mixins(ToastMixin) {
    fetchUsers!: (params: UserFetchRequest) => Promise<void>;
    deactivateUserById!: (id: number) => Promise<void>;
    deleteUserById!: (id: number) => Promise<void>;
    reactivateUserById!: (id: number) => Promise<void>;
    resetPassword!: (email: string) => Promise<void>;
    fetchReferrals!: (params: UserFetchRequest) => Promise<void>;
    fetchMostImportantOptions!: () => Promise<void>;

    users!: Pageable<User> | null;
    user!: User | null;
    error!: ApiError | null;
    mostImportantItems!: Array<MostImportantItem> | null;

    loading = false;
    updateLoading = false;

    perPage = 15;
    page = 1;
    sortBy: null | string = 'createdAt';
    sortDesc = true;
    invitingUser: User[] = new Array<User>();

    mounted(): void {
        this.loading = true;
        this.fetchUsers({
            page: this.page,
            sortBy: (this.sortBy as keyof User) ?? undefined,
            sortDesc: this.sortDesc,
        })
            .then(() => this.fetchMostImportantOptions())
            .finally(() => {
                this.loading = false;
            });
    }

    onChangePage(pageNumber: number): void {
        this.page = pageNumber;
        this.loading = true;
        this.fetchUsers({
            invitedById: this.invitingUser.length
                ? this.invitingUser[this.invitingUser.length - 1]?.id
                : undefined,
            page: this.page,
            sortBy: (this.sortBy as keyof User) ?? undefined,
            sortDesc: this.sortDesc,
        }).finally(() => {
            this.loading = false;
        });
    }

    softDeleteOnClick(id: number): void {
        this.updateLoading = true;
        this.deactivateUserById(id)
            .then(() => {
                this.showToast(
                    this.$t('users.deactivate-success').toString(),
                    this.$t('success-title').toString(),
                    ToastVariant.SUCCESS,
                );
                this.$bvModal.hide(`deactivate-modal${id}`);
            })
            .catch(() => {
                if (this.error) {
                    this.showToast(
                        this.error.toString(),
                        this.$t('users.error-title').toString(),
                        ToastVariant.DANGER,
                    );
                }
            })
            .finally(() => {
                this.updateLoading = false;
            });
    }

    reactivateOnClick(id: number): void {
        this.updateLoading = true;
        this.reactivateUserById(id)
            .then(() => {
                this.showToast(
                    this.$t('users.reactivate-success').toString(),
                    this.$t('success-title').toString(),
                    ToastVariant.SUCCESS,
                );
                this.$bvModal.hide(`reactivate-modal${id}`);
            })
            .catch(() => {
                if (this.error) {
                    this.showToast(
                        this.error.toString(),
                        this.$t('users.error-title').toString(),
                        ToastVariant.DANGER,
                    );
                }
            })
            .finally(() => {
                this.updateLoading = false;
            });
    }

    passwordResetOnClick(user: User): void {
        this.updateLoading = true;
        this.resetPassword(user.email)
            .then(() => {
                this.showToast(
                    this.$t('users.password-reset-success').toString(),
                    this.$t('success-title').toString(),
                    ToastVariant.SUCCESS,
                );
                this.$bvModal.hide(`password-reset-modal${user.id}`);
            })
            .catch(() => {
                if (this.error) {
                    this.showToast(
                        this.error.toString(),
                        this.$t('users.error-title').toString(),
                        ToastVariant.DANGER,
                    );
                }
            })
            .finally(() => {
                this.updateLoading = false;
            });
    }

    deleteUserOnClick(user: User): void {
        // TODO remove comment from this section, when the backend task will be implemented
        // this.updateLoading = true;
        // this.deleteUserById(user.id)
        //     .then(() => {
        //         this.showToast(
        //             this.$t('users.delete-user-success').toString(),
        //             this.$t('success-title').toString(),
        //             ToastVariant.SUCCESS,
        //         );
        //         this.$bvModal.hide(`delete-user-modal${user.id}`);
        //     })
        //     .catch(() => {
        //         if (this.error) {
        //             this.showToast(
        //                 this.error.toString(),
        //                 this.$t('users.error-title').toString(),
        //                 ToastVariant.DANGER,
        //             );
        //         }
        //     })
        //     .finally(() => {
        //         this.updateLoading = false;
        //     });
    }

    getDisabledState(email: string): boolean {
        return this.user?.email === email;
    }

    get getTableFields(): Field[] {
        return [
            {
                key: 'createdAt',
                label: this.$i18n.t('users.field.createdAt').toString(),
                sortable: true,
            },
            {
                key: 'email',
                label: this.$i18n.t('users.field.email').toString(),
                sortable: true,
            },
            {
                key: 'firstName',
                label: this.$i18n.t('users.field.first-name').toString(),
                sortable: true,
            },
            {
                key: 'lastName',
                label: this.$i18n.t('users.field.last-name').toString(),
                sortable: true,
            },
            {
                key: 'referralsCount',
                label: this.$i18n.t('users.field.referrals-count').toString(),
                sortable: true,
            },
            {
                key: 'timelineCount',
                label: this.$i18n.t('users.field.timeline-count').toString(),
                sortable: true,
            },
            {
                key: 'memoryCount',
                label: this.$i18n.t('users.field.memory-count').toString(),
                sortable: true,
            },
            {
                key: 'questionSetCount',
                label: this.$i18n
                    .t('users.field.question-set-count')
                    .toString(),
                sortable: true,
            },
            {
                key: 'storageUsed',
                label: this.$i18n.t('users.field.storage-used').toString(),
                sortable: true,
            },
            {
                key: 'mostImportantOptions',
                label: this.$i18n.t('users.field.most-important').toString(),
                sortable: false,
            },
            {
                key: 'actions',
                label: this.$i18n.t('users.field.actions').toString(),
            },
        ];
    }

    sortTable(ctx: BvTableCtxObject): void {
        this.sortBy = ctx.sortBy;
        this.sortDesc = ctx.sortDesc;
        this.loading = true;
        this.fetchUsers({
            invitedById: this.invitingUser.length
                ? this.invitingUser[this.invitingUser.length - 1]?.id
                : undefined,
            page: this.page,
            sortBy: (this.sortBy as keyof User) ?? undefined,
            sortDesc: this.sortDesc,
        }).finally(() => {
            this.loading = false;
        });
    }

    listReferrals(user: User | null): void {
        if (user == null || user.referralsCount > 0) {
            this.loading = true;
            this.page = 1;

            const params = {
                page: this.page,
                sortBy: (this.sortBy as keyof User) ?? undefined,
                sortDesc: this.sortDesc,
            };

            if (user) {
                this.invitingUser.push(user);
                this.fetchReferrals({
                    ...params,
                    invitedById: user.id,
                }).finally(() => {
                    this.loading = false;
                });
            } else {
                this.invitingUser.pop();
                let u;
                if (this.invitingUser.length) {
                    u = this.invitingUser[this.invitingUser.length - 1];
                }
                this.fetchReferrals({
                    ...params,
                    invitedById: u?.id,
                }).finally(() => {
                    this.loading = false;
                });
            }
        }
    }

    getSelectedMostImportantOptions(
        selection: Array<MostImportantItem>,
    ): number[] {
        return selection
            .map((selectedItem: MostImportantItem) => {
                return (
                    this.mostImportantItems?.findIndex(
                        (item: MostImportantItem) =>
                            item.id === selectedItem.id,
                    ) ?? -1
                );
            })
            .filter((index: number) => index !== -1);
    }

    formatCreatedAt(isoDateString: string): string {
        return formatISODateStringToCreatedAtString(isoDateString);
    }
}
