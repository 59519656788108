import { format, parseISO } from 'date-fns';
import { enGB } from 'date-fns/locale';

export default function formatISODateStringToCreatedAtString(
    isoDateString: string,
): string {
    return format(parseISO(isoDateString), 'dd.LL.yyyy HH:mm', {
        locale: enGB,
    });
}
